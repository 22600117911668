import React from 'react'
import { Route, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Users = ({ match }) => {
    return (
        <div className="form-wrapper mx-auto py-4 px-3">
            <Route exact path={match.path} render={ () => (
                <>
                    <div className="d-flex justify-content-between mb-4">
                        <div className="_search">
                            <div className="input-group input-group-sm">
                                <input type="search" className="form-control search-content" placeholder="Search"/>

                                <div className="input-group-append">
                                    <button type="submit" className="btn input-group-text">
                                        <FontAwesomeIcon icon="search" />
                                    </button>  
                                </div>
                            </div>            
                        </div>

                        <div className="_action">
                            <Link to={`${match.path}/create`} className="btn disabled"><FontAwesomeIcon icon="plus" /> CREATE</Link>
                        </div>
                    </div>              
                </>                
            ) } /> 
        </div>  
    )
}

export default Users
